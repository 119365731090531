import {
    GET_SEARCH,
    GET_SEARCH_ERROR,
    GET_SEARCH_SUCCESS,
    RESET_SEARCH,
    SET_OVERLAY_OPEN,
} from './constants';
import { ISearchResultSectionWrapper } from './interface';
import { ExtractActions } from '@vfde-sails/core';

/**
 * action to get search
 */
const getSearch = (query: string) => ({
    type: GET_SEARCH,
    query,
});

/**
 * action to get search result success
 */
const getSearchSuccess = (result: ISearchResultSectionWrapper) => ({
    type: GET_SEARCH_SUCCESS,
    sections: result.sections,
});

/**
 * action to get search result success
 */
export const getSearchError = () => ({
    type: GET_SEARCH_ERROR,
});

/**
 * Set overlay open
 */
const setOverlayOpen = (open: boolean) => ({
    type: SET_OVERLAY_OPEN,
    open,
});

/**
 * Reset search
 */
const resetSearch = () => ({
    type: RESET_SEARCH,
});

/**
 * Search actions
 */
export const searchActionsCreators = {
    getSearch,
    getSearchSuccess,
    getSearchError,
    setOverlayOpen,
    resetSearch,
};

/**
 * Search action dispatchers
 */
export const searchActionDispatchers = {
    getSearch,
    setOverlayOpen,
    resetSearch,
};

/**
 * Utility type to get the type for the actions
 */
export type SearchActionCreators = typeof searchActionsCreators;

/**
 * Utility type to get the type for the actions
 */
export type SearchActionDispatchers = typeof searchActionDispatchers;
/**
 * Basket action type
 */
export type SearchAction = ExtractActions<SearchActionCreators>;
