import {
    ERROR_NOTIFICATION,
    FORM_CLASSNAME,
    HIDDEN_QUERY_INPUT_ID,
    OVERLAY_OPENER_ID,
    RESULTS_AREA_CLASSNAME,
    SEARCH_DEBOUNCE_DELAY,
    SEARCH_INPUT_CONTAINER,
    SHOW_RESULTS_BOTTOM_BUTTON_CLASSNAME,
    SHOW_RESULTS_TOP_BUTTON_CLASSNAME,
} from 'Container/GlobalSearch/constants';
import { mountGlobalSearchOverlay } from 'Container/GlobalSearch/components/GlobalSearchOverlay';
import { ISearchElements } from 'Container/GlobalSearch/interface';
import { sanitizeInput } from 'Container/GlobalSearch/helpers/sanitizeInput';
import mountButton from '../../../components/Button';
import mountSearchInput from 'Container/GlobalSearch/components/SearchInput';
import mountSearchResultList from 'Container/GlobalSearch/components/SearchResultList';
import { trackTriggerAutosuggest } from '../helpers/tracking';
import { SearchActionDispatchers } from 'Container/GlobalSearch/actions';
import mountNotification from 'Container/GlobalSearch/components/Notification';
import { NO_PATTERN_BUSINESS_LOGIC } from '@vfde-brix/core';
import { debounce } from '@vfde-sails/utils';

const mountSearchOverlay = (id: string, actions: SearchActionDispatchers): ISearchElements | undefined => {
    const containerElement = document.getElementById(id) as HTMLElement;

    if (!containerElement) {
        return;
    }

    // This is not a Brix button, so we can't mount it
    const openOverlayElement = document.getElementById(OVERLAY_OPENER_ID);
    // open overlay on "search icon" click by the global navi
    openOverlayElement && openOverlayElement.addEventListener('click', e => {
        e.preventDefault();
        actions.setOverlayOpen(true);
    });

    return mountSearchElements(containerElement, actions);
};

/**
 * Toggle overlay visibility
 */
export const toggleOverlay = ({ overlay }: ISearchElements, force?: boolean) => {
    if (force === undefined) {
        overlay.toggle();

        return;
    }

    if (force) {
        overlay.open();
    }
    else {
        overlay.close();
    }
};

const mountSearchElements = (containerElement: HTMLElement, actions: SearchActionDispatchers): ISearchElements => {
    let trackedTriggerAutosuggest = false;

    const form = containerElement.querySelector<HTMLFormElement>(`.${FORM_CLASSNAME}`)!;
    const hiddenQueryInput = form.querySelector<HTMLInputElement>(`#${HIDDEN_QUERY_INPUT_ID}`)!;

    form.addEventListener('submit', e => {
        const sanitizedInputValue = sanitizeInput(inputField.getInputField().value);

        if (!sanitizedInputValue) {
            // don't submit form with empty input
            e.preventDefault();
        }

        hiddenQueryInput.value = sanitizedInputValue;
    });

    const inputField = mountSearchInput(`#${SEARCH_INPUT_CONTAINER}`, {
        onClick: (e: Event, input: HTMLInputElement) => {
            // handle click on 'X' button of search input field
            input.focus();
            actions.resetSearch();
        },
        input: {
            onInput: debounce((e: Event, value: string) => {
                const sanitizedInputValue = sanitizeInput(value);

                const valueLength = sanitizedInputValue.length;

                if (valueLength === 0) {
                    // reset search results when input is emptied
                    actions.resetSearch();

                    return;
                }

                if (valueLength === 1) {
                    // min length to trigger a search is 2
                    return;
                }

                // trigger search
                actions.getSearch(sanitizedInputValue);

                if (!trackedTriggerAutosuggest) {
                    trackTriggerAutosuggest();
                    trackedTriggerAutosuggest = true;
                }
            }, SEARCH_DEBOUNCE_DELAY),
        },
    });

    const openOverlayElement = document.getElementById(OVERLAY_OPENER_ID);

    const overlay = mountGlobalSearchOverlay(
        containerElement,
        inputField,
        actions,
        {
            onClose: () => {
                // focus open button after closing the search overlay
                openOverlayElement?.focus({ preventScroll: true });
                // restore flag so everytime the overlay is opened again
                // the autosuggest 'trigger' action may be tracked again
                trackedTriggerAutosuggest = false;
            },
        },
    );

    const showResultsTopButton = mountButton(`.${SHOW_RESULTS_TOP_BUTTON_CLASSNAME}`)!;
    const showResultsBottomButton = mountButton(`.${SHOW_RESULTS_BOTTOM_BUTTON_CLASSNAME}`)!;
    const searchResultList = mountSearchResultList(RESULTS_AREA_CLASSNAME);

    const { errorText, errorHeadline } = window.globalPageOptions?.search?.textResources || {};
    const errorNotification = mountNotification(ERROR_NOTIFICATION, {
        stdTitle: errorHeadline || '',
        txtContent: errorText || '',
        optHidden: false,
        optType: 'warning',
        business: NO_PATTERN_BUSINESS_LOGIC,
    });

    return {
        overlay,
        inputField,
        searchResultList,
        showResultsTopButton,
        showResultsBottomButton,
        errorNotification,
    };
};

export default mountSearchOverlay;
