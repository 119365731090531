import produce from 'immer';
import { IInitialState } from './interface';
import {
    SET_DEFAULT_STATE,
    TOGGLE_FLYOUT_SUCCESS,
} from './constants';
import { ContactAction } from 'Container/ContactSection/actions';

/**
 * initialState
 */
export const initialState: IInitialState = {
    contactInfo: null,
    isFlyoutOpen: false,
};

/**
 * appReducer
 */
const constactSectionReducer = (state: IInitialState = initialState, action: ContactAction) =>
    produce(state, (draft: IInitialState) => {
        switch (action.type) {
            case SET_DEFAULT_STATE:
                draft.contactInfo = action.payload;
                break;
            case TOGGLE_FLYOUT_SUCCESS:
                draft.isFlyoutOpen = !!action.payload.activePage;
                break;
            default:
                return state;
        }
    });

export default constactSectionReducer;
