import { setFocusTrap } from '@vfde-brix/core';
import {
    createGlobalSearchOverlayComponent,
    IGlobalSearchOverlayBusinessLogic,
} from '@vfde-brix/global-search-overlay';
import { SearchInput } from '@vfde-brix/search-input';
import { SearchActionDispatchers } from 'Container/GlobalSearch/actions';

/**
 * Global Search Overlay
 */
export const mountGlobalSearchOverlay = (
    container: HTMLElement,
    inputField: SearchInput,
    actions: SearchActionDispatchers,
    callbacks?: {
        onOpen?: CallableFunction,
        onClose?: CallableFunction,
    },
) => {
    let removeFocusTrap: CallableFunction | undefined | null;

    const tryRemoveFocusTrap = () => {
        if (!removeFocusTrap) {
            return;
        }

        removeFocusTrap();
        removeFocusTrap = null;
    };

    const props: IGlobalSearchOverlayBusinessLogic = {
        onOpen: () => {
            removeFocusTrap = setFocusTrap(container);
            inputField.getInputField().focus();
            callbacks?.onOpen?.();
        },
        onClose: () => {
            // update state on backdrop click
            tryRemoveFocusTrap();
            actions.setOverlayOpen(false);
            actions.resetSearch();
            callbacks?.onClose?.();
        },
        closeButton: {
            onClick: () => {
                tryRemoveFocusTrap();
                actions.setOverlayOpen(false);
            },
        },
    };

    return createGlobalSearchOverlayComponent(container, props);
};
