import {
    connect,
    injectReducer,
    injectSaga,
    RootState,
} from '@vfde-sails/core';
import {
    IInitialState,
    IStateProps,
} from './interface';
import { createStructuredSelector } from 'reselect';
import './style.scss';
import {
    LOGIN_DROPDOWN_ID,
    NAMESPACE,
    NAV_ITEM_MYVF_CLASSNAME,
} from './constants';
import {
    MyVodafoneActionDispatchers,
    myVodafoneActionDispatchers,
} from './actions';
import {
    selectIsLoggedIn,
    selectIsOpen,
    selectLoginInfo,
} from './selector';
import loginSectionReducer from './reducer';
import loginSectionSaga from './saga';
import mountLoginDropdown, { renderLoginDropdown } from 'Container/MyVodafoneSection/components/LoginDropdown';
import mountLoginTrigger, {
    initLoginTriggerEvents,
    LoginTriggerElements,
    toggleLoginTrigger,
    updateLoginTrigger,
} from 'Container/MyVodafoneSection/components/LoginTrigger';

function loginSection (state: IStateProps, actions: MyVodafoneActionDispatchers) {
    injectReducer(NAMESPACE, loginSectionReducer);
    injectSaga(NAMESPACE, loginSectionSaga);

    const loginDropdown = mountLoginDropdown(LOGIN_DROPDOWN_ID, actions);
    const loginTriggerElements = mountLoginTrigger(NAV_ITEM_MYVF_CLASSNAME) || ({} as LoginTriggerElements);

    actions.getLoginInfo();

    return {
        /**
         * This function is called on first load and everytime the store changes.
         */
        getDerivedStateFromProps (newState: IStateProps, oldState: IStateProps) {
            if (newState.isLoggedIn !== oldState.isLoggedIn) {
                updateLoginTrigger(loginTriggerElements, newState);

                if (newState.isLoggedIn) {
                    renderLoginDropdown(loginDropdown!, newState);
                    initLoginTriggerEvents(loginTriggerElements, loginDropdown!, actions);
                }
            }

            if (newState.isOpen !== oldState.isOpen) {
                toggleLoginTrigger(loginTriggerElements, newState);
            }
        },
    };
}

/**
 * mapStateToProps = We are mapping the given states to props mapDispatch
 */

const mapStateToProps = createStructuredSelector<RootState<IInitialState>, IStateProps>({
    loginInfo: selectLoginInfo(),
    isOpen: selectIsOpen(),
    isLoggedIn: selectIsLoggedIn(),
});

const mountLoginSectionContainer = connect(mapStateToProps, myVodafoneActionDispatchers)(loginSection);

export default mountLoginSectionContainer;
