import {
    CLEAR_BASKET,
    GET_BASKET_COUNT,
    GET_BASKET_COUNT_SUCCESS,
} from './constants';
import { ExtractActions } from '@vfde-sails/core';

/**
 * Action to get basket count
 */
const getBasketCount = () => ({
    type: GET_BASKET_COUNT,
});

/**
 * Action for get basket count success
 */
const getBasketCountSuccess = (basketCount: number) => ({
    type: GET_BASKET_COUNT_SUCCESS,
    basketCount,
});

/**
 * Action for clearing the basket
 */
const clearBasket = () => ({
    type: CLEAR_BASKET,
});

/**
 * Basket actions
 */
export const basketActionsCreators = {
    getBasketCount,
    getBasketCountSuccess,
    clearBasket,
};

/**
 * Basket actions
 */
export const basketActionsDispatchers = {
    getBasketCount,
    clearBasket,
};

/**
 * Utility type to get the type for the actions
 */
export type BasketActionCreators = typeof basketActionsCreators;

/**
 * Utility type to get the type for the actions
 */
export type BasketActionDispatchers = typeof basketActionsDispatchers;
/**
 * Basket action type
 */
export type BasketAction = ExtractActions<BasketActionCreators>;
