/* istanbul ignore file */

import './style.scss';
import { createStore } from '@vfde-sails/core';
import mountBasketContainer from 'Container/Basket';
import mountGlobalNavigationContainer from 'Container/GlobalNavigation';
import mountGlobalSearchContainer from 'Container/GlobalSearch';
import mountMyVodafoneSectionContainer from 'Container/MyVodafoneSection';
import mountContactSectionContainer from 'Container/ContactSection';
import mountContentContainer from 'Container/Content';
import { initTouchDetection } from '@vfde-brix/core';

// init touch detection as early as possible
initTouchDetection();

createStore();

mountBasketContainer();
mountGlobalNavigationContainer();
mountGlobalSearchContainer();
mountMyVodafoneSectionContainer();
mountContactSectionContainer();
mountContentContainer();
