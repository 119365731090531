import produce from 'immer';
import { IInitialState } from './interface';
import {
    CLEAR_BASKET,
    GET_BASKET_COUNT_SUCCESS,
} from './constants';
import { BasketAction } from 'Container/Basket/actions';

/**
 * initialState
 */
export const initialState: IInitialState = {
    basketCount: 0,
};

/**
 * basketReducer
 */
const basketReducer = (state: IInitialState = initialState, action: BasketAction) =>
    produce(state, (draft: IInitialState) => {
        switch (action.type) {
            case GET_BASKET_COUNT_SUCCESS:
                draft.basketCount = action.basketCount;
                break;
            case CLEAR_BASKET:
                draft.basketCount = 0;
                break;
        }
    });

export default basketReducer;
