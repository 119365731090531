import {
    call,
    CallEffect,
    select,
    SelectEffect,
    takeLatest,
} from 'redux-saga/effects';
import { TOGGLE_FLYOUT } from 'Container/ContactSection/constants';
import beacon from '@vfde-sails/beacon';
import { selectIsFlyoutOpen } from 'Container/ContactSection/selector';
import { contactActionsCreators } from 'Container/ContactSection/actions';

/**
 * Contact section saga
 */
export default function* contactSectionSaga () {
    yield takeLatest(TOGGLE_FLYOUT, toggleFlyoutSaga);
}

/**
 * Toggle flyout by publishing to flyout app
 */
export function* toggleFlyoutSaga (
    action: ReturnType<typeof contactActionsCreators.toggleFlyout>,
): Generator<SelectEffect | CallEffect<void>, void, boolean> {
    const isOpen = yield select(selectIsFlyoutOpen());

    if (!isOpen) {
        yield call([beacon, 'publish'], 'sails:flyout', {
            type: 'TOGGLE_FLYOUT',
            payload: {
                focus: action.focus,
                activePage: 'cf-hotline',
            },
        });
    }
}
