import { initBreadcrumb } from 'Container/GlobalNavigation/helpers/breadcrumbHelper';
import { BREADCRUMB_CLASSNAME } from 'Container/GlobalNavigation/constants';

/**
 * Init breadcrumb
 */
export const mountBreadcrumb = (): CallableFunction[] => {
    const breadcrumbElement = document.getElementsByClassName(BREADCRUMB_CLASSNAME)[0] as HTMLElement;
    const resizeHandlers: CallableFunction[] = [];

    if (!breadcrumbElement) {
        return resizeHandlers;
    }

    resizeHandlers.push(initBreadcrumb(breadcrumbElement));

    return resizeHandlers;
};
