import {
    GET_LOGIN_INFO,
    GET_LOGIN_INFO_FAILED,
    GET_LOGIN_INFO_SUCCESS,
    POST_LOGOUT,
    TOGGLE_DROPDOWN_STATE,
} from './constants';
import { ILoginInfo } from './interface';
import { ExtractActions } from '@vfde-sails/core';

/**
 * action to get login info
 */
const getLoginInfo = () => ({
    type: GET_LOGIN_INFO,
});

/**
 * action to set login info
 */
const getLoginInfoSuccess = (loginInfo: ILoginInfo) => ({
    type: GET_LOGIN_INFO_SUCCESS,
    loginInfo,
});

/**
 * action to set hasError flag
 */
const getLoginInfoFailed = (hasError: boolean) => ({
    type: GET_LOGIN_INFO_FAILED,
    hasError,
});

/**
 * action to send logout request
 */
const postLogout = (redirectUrl: string) => ({
    type: POST_LOGOUT,
    redirectUrl,
});

/**
 * Set dropdown state
 */
const toggleDropdownState = (shouldOpen?: boolean) => ({
    type: TOGGLE_DROPDOWN_STATE,
    shouldOpen,
});

/**
 * Login actions
 */
export const myVodafoneActionsCreators = {
    getLoginInfo,
    getLoginInfoSuccess,
    getLoginInfoFailed,
    toggleDropdownState,
    postLogout,
};

/**
 * Login action dispatchers
 */
export const myVodafoneActionDispatchers = {
    getLoginInfo,
    toggleDropdownState,
    postLogout,
};

/**
 * Utility type to get the type for the actions
 */
export type MyVodafoneActionCreators = typeof myVodafoneActionsCreators;

/**
 * Utility type to get the type for the actions
 */
export type MyVodafoneActionDispatchers = typeof myVodafoneActionDispatchers;

/**
 * Basket action type
 */
export type MyVodafoneAction = ExtractActions<MyVodafoneActionCreators>;
