import produce from 'immer';
import {
    GET_SEARCH,
    GET_SEARCH_ERROR,
    GET_SEARCH_SUCCESS,
    RESET_SEARCH,
    SET_OVERLAY_OPEN,
} from './constants';
import { SearchAction } from 'Container/GlobalSearch/actions';
import { IInitialState } from './interface';

/**
 * initialState
 */
export const initialState: IInitialState = {
    isLoading: false,
    hasError: false,
    query: null,
    results: [],
    isOverlayOpen: false,
};

/**
 * appReducer
 */
const globalSearchReducer = (state: IInitialState = initialState, action: SearchAction) =>
    produce(state, (draft: IInitialState) => {
        switch (action.type) {
            case GET_SEARCH:
                draft.isLoading = true;
                draft.query = action.query;
                break;
            case GET_SEARCH_SUCCESS:
                draft.isLoading = false;
                draft.hasError = false;
                draft.results = action.sections;
                break;
            case GET_SEARCH_ERROR:
                draft.isLoading = false;
                draft.hasError = true;
                draft.results = [];
                break;
            case SET_OVERLAY_OPEN:
                draft.isOverlayOpen = action.open;
                break;
            case RESET_SEARCH:
                draft.hasError = false;
                draft.query = null;
                draft.results = [];
                break;
        }
    });

export default globalSearchReducer;
