import { APP } from 'Constant';
import {
    NAV_CLASSNAME,
    NAV_ICON_NAV_ITEM_CLASSNAME,
} from 'Container/GlobalNavigation/constants';

/**
 * NAMESPACE
 */
export const NAMESPACE = `${APP}/CONTACTSECTION` as const;
/**
 * SET_DEFAULT_STATE
 */
export const SET_DEFAULT_STATE = `${NAMESPACE}/SET_DEFAULT_STATE` as const;
export const TOGGLE_FLYOUT = `${NAMESPACE}/TOGGLE_FLYOUT` as const;
export const TOGGLE_FLYOUT_SUCCESS = `${NAMESPACE}/TOGGLE_FLYOUT_SUCCESS` as const;

/**
 * Meta nav item class
 */
export const META_NAV_ITEM_CLASSNAME = `${NAV_CLASSNAME}__meta-nav-item`;
/**
 * Meta nav item class for contact number
 */
export const META_NAV_ITEM_CONTACT_NUMBER_CLASSNAME = `${META_NAV_ITEM_CLASSNAME}--contact-number`;
/**
 * Meta nav item class for contact number visible
 */
export const META_NAV_ITEM_CONTACT_NUMBER_VISIBLE_CLASSNAME = `${META_NAV_ITEM_CLASSNAME}--contact-number-visible`;
/**
 * Meta nav item class for contact button
 */
export const META_NAV_ITEM_CONTACT_BUTTON_CLASSNAME = `${NAV_ICON_NAV_ITEM_CLASSNAME}--contact`;
/**
 * Meta nav item class for contact number visible
 */
export const META_NAV_ITEM_CONTACT_BUTTON_VISIBLE_CLASSNAME = `${NAV_ICON_NAV_ITEM_CLASSNAME}--contact-visible`;
/**
 * Meta nav link class
 */
export const META_NAV_LINK_CLASSNAME = `${NAV_CLASSNAME}__meta-nav-link`;
/**
 * Meta nav link contact label class
 */
export const META_NAV_LINK_CONTACT_LABEL_CLASSNAME = `${NAV_CLASSNAME}__meta-nav-link-contact-label`;
/**
 * Meta nav link contact label class
 */
export const NAV_ICON_CONTACT_CLASSNAME = `${NAV_CLASSNAME}__icon-contact`;
