import {
    SET_DEFAULT_STATE,
    TOGGLE_FLYOUT,
    TOGGLE_FLYOUT_SUCCESS,
} from './constants';
import { ExtractActions } from '@vfde-sails/core';

/**
 * action to set initial state
 */
const setDefaultState = (payload: any) => ({
    type: SET_DEFAULT_STATE,
    payload,
});

/**
 * Toggle flyout via triggering beacon to flyout app
 */
const toggleFlyout = (focus = false) => ({
    type: TOGGLE_FLYOUT,
    focus,
});

/**
 * Triggered when flyout sends back success event via beacon
 */
const toggleFlyoutSuccess = (payload: any) => ({
    type: TOGGLE_FLYOUT_SUCCESS,
    payload,
});

/**
 * Contact section actions
 */
export const contactActionsCreators = {
    setDefaultState,
    toggleFlyout,
    toggleFlyoutSuccess,
};

/**
 * Contact section action dispatchers
 */
export const contactActionDispatchers = {
    setDefaultState,
    toggleFlyout,
    toggleFlyoutSuccess,
};

/**
 * Utility type to get the type for the actions
 */
export type ContactActionCreators = typeof contactActionsCreators;

/**
 * Utility type to get the type for the actions
 */
export type ContactActionDispatchers = typeof contactActionDispatchers;
/**
 * Contact section action type
 */
export type ContactAction = ExtractActions<ContactActionCreators>;
