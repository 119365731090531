import {
    connect,
    injectReducer,
    injectSaga,
    RootState,
} from '@vfde-sails/core';
import { createStructuredSelector } from 'reselect';
import {
    IInitialState,
    IStateProps,
} from 'Container/GlobalNavigation/interface';
import globalNavigationReducer from 'Container/GlobalNavigation/reducer';
import {
    NAMESPACE,
    NAV_ITEM_SELECTED_CLASSNAME,
} from 'Container/GlobalNavigation/constants';
import globalNavigationSaga from 'Container/GlobalNavigation/saga';
import './style.scss';
import { mountBreadcrumb } from 'Container/GlobalNavigation/components/Breadcrumb';
import { mountFooter } from 'Container/GlobalNavigation/components/Footer';
import {
    mountMdd,
    toggleMdd,
    toggleMddNavItem,
} from 'Container/GlobalNavigation/components/Mdd';
import { mountSubnav } from 'Container/GlobalNavigation/components/Subnav';
import {
    NavigationActionDispatchers,
    navigationActionDispatchers,
} from 'Container/GlobalNavigation/action';
import { selectIsMddOpen } from 'Container/GlobalNavigation/selector';
import { getDesktopMediaQueryList } from '@vfde-brix/core';

function globalNavigation (state: IStateProps, actions: NavigationActionDispatchers) {
    injectReducer(NAMESPACE, globalNavigationReducer);
    injectSaga(NAMESPACE, globalNavigationSaga);

    // ResizeHandlers are triggered on every pixel of resize
    const resizeHandlers: CallableFunction[] = [];

    // breakpointHandlers are triggered when the viewport switches from mobile to desktop or vice versa
    const breakpointHandlers: CallableFunction[] = [];

    breakpointHandlers.push(...mountMdd(actions));
    breakpointHandlers.push(...mountSubnav());
    breakpointHandlers.push(...mountFooter());

    resizeHandlers.push(...mountBreadcrumb()); // breadcrumb needs to track resize to adjust its scroll position

    const desktopBreakpoint = getDesktopMediaQueryList();
    desktopBreakpoint.addEventListener('change', () => {
        breakpointHandlers.forEach(handlerFn => {
            handlerFn();
        });
    });

    // Prevent triggering while still resizing
    let timeoutId: NodeJS.Timeout | null = null;

    window.addEventListener('resize', () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            timeoutId = null;

            resizeHandlers.forEach(handlerFn => {
                handlerFn();
            });
        }, 200);
    });

    return {
        /**
         * This function is called on first load and everytime the store changes.
         */
        getDerivedStateFromProps (newState: IStateProps, oldState: IStateProps) {
            if (newState.isMddOpen !== oldState.isMddOpen) {
                toggleMdd(false, newState.isMddOpen);

                if (newState.isMddOpen) {
                    const activeItems = document.getElementsByClassName(NAV_ITEM_SELECTED_CLASSNAME) as HTMLCollectionOf<HTMLLIElement>;
                    toggleMddNavItem(activeItems);
                }
            }
        },
    };
}

/**
 * mapStateToProps = We are mapping the given states to props mapDispatch
 */

const mapStateToProps = createStructuredSelector<RootState<IInitialState>, IStateProps>({
    isMddOpen: selectIsMddOpen(),
});

const mountGlobalNavigationContainer = connect(mapStateToProps, navigationActionDispatchers)(globalNavigation);

export default mountGlobalNavigationContainer;
