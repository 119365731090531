import {
    createSelector,
    Selector,
} from 'reselect';
import { initialState } from './reducer';
import { NAMESPACE } from './constants';
import { RootState } from '@vfde-sails/core';
import { IInitialState } from './interface';

const selectState = (state: RootState<IInitialState>): IInitialState => state[NAMESPACE] || initialState;

const selectIsLoading = (): Selector<RootState<IInitialState>, IInitialState['isLoading']> =>
    createSelector(selectState, state => state.isLoading);

const selectHasError = (): Selector<RootState<IInitialState>, IInitialState['hasError']> =>
    createSelector(selectState, state => state.hasError);

const selectQuery = (): Selector<RootState<IInitialState>, IInitialState['query']> =>
    createSelector(selectState, state => state.query);

const selectResults = (): Selector<RootState<IInitialState>, IInitialState['results']> =>
    createSelector(selectState, state => state.results);

const selectIsOverlayOpen = (): Selector<RootState<IInitialState>, IInitialState['isOverlayOpen']> =>
    createSelector(selectState, state => state.isOverlayOpen);

export {
    selectIsLoading,
    selectHasError,
    selectQuery,
    selectResults,
    selectIsOverlayOpen,
};
