import { APP } from 'Constant';
import { NAV_CLASSNAME } from 'Container/GlobalNavigation/constants';

/**
 * NAMESPACE
 */
export const NAMESPACE = `${APP}/LOGINSECTION` as const;

/**
 * Actions
 */
export const GET_LOGIN_INFO = `${NAMESPACE}/GET_LOGIN_INFO` as const;
export const GET_LOGIN_INFO_SUCCESS = `${NAMESPACE}/GET_LOGIN_INFO_SUCCESS` as const;
export const GET_LOGIN_INFO_FAILED = `${NAMESPACE}/GET_LOGIN_INFO_FAILED` as const;
export const POST_LOGOUT = `${NAMESPACE}/POST_LOGOUT` as const;
export const TOGGLE_DROPDOWN_STATE = `${NAMESPACE}/TOGGLE_DROPDOWN_STATE` as const;

/**
 * IDs
 */
export const LOGIN_DROPDOWN_ID = `${NAV_CLASSNAME}-login-dropdown` as const;
export const LOGOUT_BUTTON_ID = `${NAV_CLASSNAME}-logout-button` as const;

/**
 * Class names
 */
export const NAV_ITEM_MYVF_CLASSNAME = `${NAV_CLASSNAME}__icon-nav-item--my-vf` as const;
export const DROPDOWN_OPENER_CLASSNAME = `${NAV_CLASSNAME}-open-login-dropdown` as const;
export const DROPDOWN_OPENER_OPEN_CLASSNAME = `${DROPDOWN_OPENER_CLASSNAME}--open` as const;
export const DROPDOWN_OPENER_LOGGED_IN_CLASSNAME = `${DROPDOWN_OPENER_CLASSNAME}--logged-in` as const;
export const DROPDOWN_OPENER_TOUCH_CLASSNAME = `${DROPDOWN_OPENER_CLASSNAME}--touch` as const;
export const DROPDOWN_OPENER_INDICATOR_CLASSNAME = `${DROPDOWN_OPENER_CLASSNAME}__indicator` as const;
export const DROPDOWN_CLASSNAME = `${NAV_CLASSNAME}-login-dropdown` as const;
export const DROPDOWN_INNER_CLASSNAME = `${DROPDOWN_CLASSNAME}__inner` as const;
export const DROPDOWN_LOGOUT_BUTTON_CLASSNAME = `${DROPDOWN_CLASSNAME}__logout` as const;

/**
 * Miscellaneous
 */
export const LOGIN_COOKIE = 'MDDKeks' as const;
export const BSHOP_LOGOUT_URL = '/api/commerce/authentication/logout' as const;
