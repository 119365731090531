import {
    ISearchResultListItem,
    ISearchResultListSection,
} from '@vfde-brix/search-result-list';
import {
    ISearchAPIResultItem,
    ISearchResultSectionWrapper,
} from '../interface';
import { getNoResults } from 'Container/GlobalSearch/helpers/getNoResults';

/**
 * Group search results by 'ecs_group' property
 */
export const groupSearchResult = (query: string, apiResults: ISearchAPIResultItem[]): ISearchResultSectionWrapper => {
    if (!Array.isArray(apiResults) || apiResults.length === 0) {
        return getNoResults(query);
    }

    // group results by their 'ecs_group' property in a object
    const groups = apiResults.reduce((lastValue: { [groupName: string]: ISearchAPIResultItem[]}, currentValue: ISearchAPIResultItem) => {
        lastValue[currentValue.ecs_group] = lastValue[currentValue.ecs_group] || [];
        lastValue[currentValue.ecs_group].push(currentValue);

        return lastValue;
    }, {});

    const sections: ISearchResultListSection<ISearchResultListItem>[] = [];
    let resultCount = 0;

    // convert groups object to ISearchResultListSection
    Object.keys(groups).forEach(header => {
        const section = {
            header,
            items: groups[header].map(item => ({
                image: item.ecs_image,
                text: item.ecs_name,
                link: item.ecs_url,
            })),
        };

        sections.push(section);
        resultCount += section.items.length;
    });

    return {
        sections,
        resultCount,
    };
};
