import {
    createSelector,
    Selector,
} from 'reselect';
import { initialState } from './reducer';
import { NAMESPACE } from './constants';
import { IInitialState } from './interface';
import { RootState } from '@vfde-sails/core';

const selectState = (state: RootState<IInitialState>): IInitialState => state[NAMESPACE] || initialState;

const selectContactNumber = (): Selector<RootState<IInitialState>, string | null> =>
    createSelector(selectState, state => state.contactInfo?.mobileCallNumber || null);

const selectIsFlyoutOpen = (): Selector<RootState<IInitialState>, IInitialState['isFlyoutOpen']> =>
    createSelector(selectState, state => state.isFlyoutOpen);

export {
    selectContactNumber,
    selectIsFlyoutOpen,
};
