/**
 * interface state props
 */
export interface IStateProps {
    /**
     * MDD is open
     */
    isMddOpen: boolean;
}

/**
 * Initial state
 */
export interface IInitialState {
    /**
     * MDD is open
     */
    isMddOpen: boolean;
}

/**
 * Possible skip link types
 */
export enum SkipToLinkType {
    Content,
    Navigation,
}

/**
 * MDD mouse move directions
 */
export enum MddDirections {
    In = 'in',
    Out = 'out',
    Up = 'up',
    Down = 'down',
    Horizontal = 'horizontal',
}

/**
 * Navigation specific mouse events
 */
export type NavMouseEnterOrNavMouseLeave = 'nav:mouseenter' | 'nav:mouseleave';
/**
 * Mouseenter and mouseleave events as a type
 */
export type MouseEnterOrMouseLeave = 'mouseenter' | 'mouseleave';
