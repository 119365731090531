import {
    IMyVodafoneSectionProperties,
    IMyVodafoneSectionTextResources,
    IStateProps,
} from 'Container/MyVodafoneSection/interface';
import {
    DROPDOWN_OPENER_CLASSNAME,
    LOGOUT_BUTTON_ID,
} from 'Container/MyVodafoneSection/constants';
import { MyVodafoneActionDispatchers } from 'Container/MyVodafoneSection/actions';

const mountLoginDropdown = (id: string, actions: MyVodafoneActionDispatchers): HTMLElement | undefined => {
    const containerElement = document.getElementById(id);

    if (!containerElement) {
        return;
    }

    initLoginDropdownEvents(containerElement, actions);

    // A bit absurd for now, but once we get real master components it should make sense
    return containerElement;
};

/**
 * Render the login dropdown content (only if user is logged in)
 */
export const renderLoginDropdown = (dropdown: HTMLElement, state: IStateProps): void => {
    if (!dropdown) {
        return;
    }

    const templateData: IMyVodafoneSectionProperties = {
        lastLoginDay: null,
        lastLoginTime: null,
        lastLoginDatetime: null,
        textResources: window.globalPageOptions?.myVf?.textResources || ({} as IMyVodafoneSectionTextResources),
    };

    const { lastLoginTimestamp = '' } = state.loginInfo || {};
    const parsedLoginTimestamp = parseInt(lastLoginTimestamp, 10);
    const lastLoginDate = parsedLoginTimestamp ? new Date(parsedLoginTimestamp) : null;

    if (lastLoginDate) {
        const locale = 'de';
        const twoDigit = '2-digit';

        templateData.lastLoginDay = lastLoginDate.toLocaleDateString(locale, {
            day: twoDigit,
            month: twoDigit,
            year: 'numeric',
        });

        templateData.lastLoginTime = lastLoginDate.toLocaleTimeString(locale, {
            hour: twoDigit,
            minute: twoDigit,
            second: twoDigit,
        });

        templateData.lastLoginDatetime = lastLoginDate.toISOString();
    }

    const renderLoginSectionContent = require('./templates/login-dropdown.pug');

    dropdown.innerHTML = renderLoginSectionContent(templateData);
};

const initLoginDropdownEvents = (dropdown: HTMLElement, actions: MyVodafoneActionDispatchers) => {
    // stop dropdown events from bubbling up
    ['click', 'keydown'].forEach(eventType => {
        dropdown.addEventListener(eventType, (event: Event) => {
            event.stopPropagation();
        });
    });

    // Not nice but because of hen/egg problem we cannot pass the navLink element in this function
    const navLink = document.querySelector<HTMLAnchorElement>(`.${DROPDOWN_OPENER_CLASSNAME}`);

    const handleDropDownFocusOut = (e: FocusEvent) => {
        const target = e.target as HTMLElement;
        const relatedTarget = e.relatedTarget as HTMLElement;

        if (!relatedTarget) {
            // focus jumped from the dropdown out of the document/page so we close the dropdown
            actions.toggleDropdownState(false);

            return;
        }

        const compareDocumentPositionResult = dropdown.compareDocumentPosition(relatedTarget);

        if (compareDocumentPositionResult & Node.DOCUMENT_POSITION_CONTAINED_BY) {
            // focus is still within the dropdown, so we do nothing
            return;
        }

        if (e.relatedTarget === navLink) {
            // focus moved from inside the dropdown to the opener element
            // and because we want to keep the dropdown open in that case, we do nothing
            return;
        }

        // focus jumped out of the dropDown to any element which is not the dropdown opener, so we close the dropdown
        actions.toggleDropdownState(false);

        if (
            compareDocumentPositionResult & Node.DOCUMENT_POSITION_FOLLOWING
            || (
                target.hasAttribute('tabindex')
                && relatedTarget.hasAttribute('tabindex')
                && target.tabIndex + 1 === relatedTarget.tabIndex
            )
        ) {
            // in case the focus jumped to a following element
            // either via natural DOM-order (checked with compareDocumentPosition)
            // or via tabindex-order (checked by comparing the tabindex attributes)
            // we focus the opener again
            navLink!.focus();
        }
    };

    const handleDropdownKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            // handle pressing escape when focus is inside the dropdown,
            // to close the dropdown and set the focus to the navLink
            actions.toggleDropdownState(false);
            navLink!.focus();
        }
    };

    const handleLogout = (e: MouseEvent) => {
        const target = e.target as HTMLElement;

        // check if target was the logout button or a descendant of the logout button
        const logoutButton = target.closest(`#${LOGOUT_BUTTON_ID}`);

        if (logoutButton) {
            // get the href attribute from the logout button
            // and pass it to the logout action
            const href = (logoutButton as HTMLLinkElement).href;
            actions.postLogout(href);
        }
    };

    // handle focusout on the dropdown
    dropdown.addEventListener('focusout', handleDropDownFocusOut);

    // handle keydown on the dropdown
    dropdown.addEventListener('keydown', handleDropdownKeydown);

    // needs to be bound to the dropdown element cause initial the logout button is not there
    // correct element then checked via event delegation
    dropdown.addEventListener('click', handleLogout);
};

export default mountLoginDropdown;
