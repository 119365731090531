import {
    createSelector,
    Selector,
} from 'reselect';
import { initialState } from './reducer';
import { NAMESPACE } from './constants';
import { IInitialState } from './interface';
import { RootState } from '@vfde-sails/core';

const selectState = (state: RootState<IInitialState>): IInitialState => state[NAMESPACE] || initialState;

const selectBasketCount = (): Selector<RootState<IInitialState>, IInitialState['basketCount']> =>
    createSelector(selectState, (state: IInitialState) => state.basketCount);

export {
    selectBasketCount,
};
