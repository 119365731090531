import {
    call,
    CallEffect,
    put,
    PutEffect,
    takeLatest,
} from 'redux-saga/effects';
import {
    BASKET_COUNT_DEFAULT_COOKIE,
    BASKET_COUNT_OLS_COOKIE,
    GET_BASKET_COUNT,
} from './constants';
import { basketActionsCreators } from './actions';
import { getCookie } from '@vfde-sails/utils';

/**
 * Reads basket count from cookie
 */
export function* getBasketCountSaga (): Generator<
    CallEffect<string | null>
    | PutEffect<ReturnType<typeof basketActionsCreators.getBasketCountSuccess>>,
    void,
    ReturnType<typeof getCookie>> {
    const isValidBasketCount = (value: any): value is number => typeof value === 'number' && !Number.isNaN(value);
    const olsCookieValue = yield call(getCookie, BASKET_COUNT_OLS_COOKIE);
    const defaultCookieValue = yield call(getCookie, BASKET_COUNT_DEFAULT_COOKIE);
    let parsedCookieValue = olsCookieValue ? parseInt(olsCookieValue, 10) : olsCookieValue;

    if (!isValidBasketCount(parsedCookieValue)) {
        parsedCookieValue = defaultCookieValue ? parseInt(defaultCookieValue, 10) : defaultCookieValue;

        if (!isValidBasketCount(parsedCookieValue)) {
            parsedCookieValue = 0;
        }
    }

    const basketCount = Math.max(0, parsedCookieValue);
    yield put(basketActionsCreators.getBasketCountSuccess(basketCount));
}

/**
 * basketSaga
 */
export default function* basketSaga () {
    yield takeLatest(GET_BASKET_COUNT, getBasketCountSaga);
}
