import {
    SET_MDD_STATE,
    TRIGGER_SKIP_TO_LINK,
} from 'Container/GlobalNavigation/constants';
import { SkipToLinkType } from 'Container/GlobalNavigation/interface';
import { ExtractActions } from '@vfde-sails/core';

/**
 * Skip to link action
 */
const triggerSkipToLink = (linkType: SkipToLinkType, href: string) => ({
    type: TRIGGER_SKIP_TO_LINK,
    linkType,
    href,
});

/**
 * Set MDD state to either open (true) or closed (false)
 * @param shouldOpen - True to open the MDD, false to close it
 */
const setMddState = (shouldOpen: boolean) => ({
    type: SET_MDD_STATE,
    shouldOpen,
});

/**
 * Global navigation actions
 */
export const navigationActionsCreators = {
    triggerSkipToLink,
    setMddState,
};

/**
 * Global navigation action dispatchers
 */
export const navigationActionDispatchers = {
    setMddState,
    triggerSkipToLink,
};

/**
 * Utility type to get the type for the actions
 */
export type NavigationActionCreators = typeof navigationActionsCreators;
/**
 * Utility type to get the type for the actions
 */
export type NavigationActionDispatchers = typeof navigationActionDispatchers;
/**
 * Navigation action type
 */
export type NavigationAction = ExtractActions<NavigationActionCreators>;
