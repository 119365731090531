import replaceCMSPlaceholder from 'Helper/replaceCMSPlaceholder';
import { ISearchResultSectionWrapper } from '../interface';
import { NO_RESULT_ID } from 'Container/GlobalSearch/constants';

/**
 * get no results item
 */
export const getNoResults = (searchString: string): ISearchResultSectionWrapper => {
    const { errorImageUrl = '', textResources: { noResultText = '' } } = window.globalPageOptions?.search || { textResources: {} };

    return {
        sections: [
            {
                id: NO_RESULT_ID,
                items: [
                    {
                        image: errorImageUrl,
                        text: replaceCMSPlaceholder(noResultText, {
                            searchString,
                        }),
                        link: '',
                    },
                ],
            },
        ],
        resultCount: 1,
    };
};
