import {
    APP,
    GLOBAL_CLASSNAME_PREFIX,
} from 'Constant';
import { BASE_CLASSNAME as OVERLAY_CLASSNAME } from '@vfde-brix/global-search-overlay';

/**
 * NAMESPACE
 */
export const NAMESPACE = `${APP}/GLOBALSEARCH` as const;
/**
 * Actions
 */
export const SET_DEFAULT_STATE = `${NAMESPACE}/SET_DEFAULT_STATE` as const;
export const GET_SEARCH = `${NAMESPACE}/GET_SEARCH` as const;
export const GET_SEARCH_SUCCESS = `${NAMESPACE}/GET_SEARCH_SUCCESS` as const;
export const GET_SEARCH_ERROR = `${NAMESPACE}/GET_SEARCH_ERROR` as const;
export const SET_OVERLAY_OPEN = `${NAMESPACE}/SET_OVERLAY_OPEN` as const;
export const RESET_SEARCH = `${NAMESPACE}/RESET_SEARCH` as const;

/**
 * Class names
 */
export const OVERLAY_CONTAINER_CLASSNAME = `${OVERLAY_CLASSNAME}__container`;
export const OVERLAY_CONTENT_CLASSNAME = `${OVERLAY_CLASSNAME}__content`;
export const FORM_CLASSNAME = `${OVERLAY_CLASSNAME}__form`;
export const RESULTS_AREA_CLASSNAME = `${OVERLAY_CLASSNAME}__results`;
export const OVERLAY_CLOSE_BUTTON_CLASSNAME = `${OVERLAY_CLASSNAME}__close`;
export const SHOW_RESULTS_TOP_BUTTON_CLASSNAME = `${OVERLAY_CLASSNAME}__search-button`;
export const SHOW_RESULTS_BOTTOM_BUTTON_CLASSNAME = `${OVERLAY_CLASSNAME}__show-all`;

/**
 * IDs
 */
export const OVERLAY_WRAPPER_ID = `${GLOBAL_CLASSNAME_PREFIX}-search-overlay-wrapper`;
export const OVERLAY_OPENER_ID = `${GLOBAL_CLASSNAME_PREFIX}-open-search-overlay`;
export const HIDDEN_QUERY_INPUT_ID = 'mc-search-customer-query';
export const SEARCH_INPUT_ID = 'mc-search-input-field';
export const SEARCH_INPUT_CONTAINER = 'mc-search-input-container';
export const ERROR_NOTIFICATION = 'mc-search-notification';

/**
 * URLs
 */
export const SEARCH_API_URL =
    '/api/search/SearchBridge/search?sv=suggest&wt=json&Filter=customertype:private&searchCustomerContext=private%7Cdomain.shop&q=';

/**
 * Miscellaneous
 */
export const WAIT_FOR_TRANSITION = 1000;
export const TRACKING_SEARCH = 'search';
export const NO_RESULT_ID = 'no-result';
export const SEARCH_DEBOUNCE_DELAY = 250;
