import produce from 'immer';
import { IInitialState } from './interface';
import {
    GET_LOGIN_INFO_FAILED,
    GET_LOGIN_INFO_SUCCESS,
    TOGGLE_DROPDOWN_STATE,
} from './constants';
import { MyVodafoneAction } from 'Container/MyVodafoneSection/actions';

/**
 * initialState
 */
export const initialState: IInitialState = {
    hasError: false,
    loginInfo: null,
    isOpen: false,
};

/**
 * loginSectionReducer
 */
const loginSectionReducer = (state: IInitialState = initialState, action: MyVodafoneAction) =>
    produce(state, (draft: IInitialState) => {
        switch (action.type) {
            case GET_LOGIN_INFO_SUCCESS:
                draft.loginInfo = action.loginInfo;
                break;
            case GET_LOGIN_INFO_FAILED:
                draft.hasError = action.hasError;
                break;
            case TOGGLE_DROPDOWN_STATE:
                draft.isOpen = action.shouldOpen ?? !state.isOpen;
                break;
        }
    });

export default loginSectionReducer;
