import {
    createSelector,
    Selector,
} from 'reselect';
import { initialState } from './reducer';
import { NAMESPACE } from './constants';
import { RootState } from '@vfde-sails/core';
import { IInitialState } from './interface';

const selectState = (state: RootState<IInitialState>): IInitialState => state[NAMESPACE] || initialState;

const selectLoginInfo = (): Selector<RootState<IInitialState>, IInitialState['loginInfo']> =>
    createSelector(selectState, state => state.loginInfo);

const selectHasError = (): Selector<RootState<IInitialState>, IInitialState['hasError']> =>
    createSelector(selectState, state => state.hasError);

const selectIsOpen = (): Selector<RootState<IInitialState>, IInitialState['isOpen']> =>
    createSelector(selectState, state => state.isOpen);

const selectIsLoggedIn = (): Selector<RootState<IInitialState>, boolean> =>
    createSelector(selectLoginInfo(), loginInfo => !!loginInfo);

export {
    selectLoginInfo,
    selectHasError,
    selectIsOpen,
    selectIsLoggedIn,
};
