import produce from 'immer';
import { IInitialState } from 'Container/GlobalNavigation/interface';
import { SET_MDD_STATE } from 'Container/GlobalNavigation/constants';
import { NavigationAction } from 'Container/GlobalNavigation/action';

/**
 * initialState
 */
export const initialState: IInitialState = {
    isMddOpen: false,
};

/**
 * appReducer
 */
const globalNavigationReducer = (state: IInitialState = initialState, action: NavigationAction) =>
    produce(state, (draft: IInitialState) => {
        switch (action.type) {
            case SET_MDD_STATE:
                draft.isMddOpen = action.shouldOpen;
                break;
            default:
                return state;
        }
    });

export default globalNavigationReducer;
