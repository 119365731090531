import {
    FOOTNOTE_ID_PREFIX,
    FOOTNOTE_LINK_CLASSNAME,
    Footnotes,
    createFootnotesComponent,
} from '@vfde-brix/footnotes';
import {
    CONTENT_ID,
    FOOTNOTES_CONTAINER_ID,
    ONLOAD_FOOTNOTE_SCROLL_DELAY,
} from '../constants';
import { NO_PATTERN_BUSINESS_LOGIC } from '@vfde-brix/core';

/**
 * Initialize Footnotes
 */
export const initFootnotes = () => {
    const contentElement = document.getElementById(CONTENT_ID);
    const footnotesContainer = document.getElementById(FOOTNOTES_CONTAINER_ID);

    if (!contentElement || !footnotesContainer) {
        return;
    }

    // mount the footnotes component
    const footnotes = createFootnotesComponent(footnotesContainer, NO_PATTERN_BUSINESS_LOGIC);

    connectFootnotes([contentElement]);

    addClickListener(targetFootnoteNumber => {
        footnotes.scrollToFootnote(targetFootnoteNumber);
    });

    observeDom(contentElement, targetElements => {
        connectFootnotes(targetElements);
    });

    scrollToFootnoteFromLocationHash(targetFootnoteNumber => {
        setTimeout(() => {
            footnotes.scrollToFootnote(targetFootnoteNumber);
        }, ONLOAD_FOOTNOTE_SCROLL_DELAY);
    });

    // make footnotes available in the window so apps could update them if needed
    window.VF = window.VF || {};
    window.VF.SAILS = window.VF.SAILS || {};
    window.VF.SAILS.footnotes = footnotes;
};

/**
 * Searches for sup elements in the given contextElements and wraps them into an anchor referencing the footnote ID
 * @param contextElements Array of elements
 */
const connectFootnotes = (contextElements: HTMLElement[]) => {
    // only get those sups which are not nested in an anchor already
    const sups = contextElements.flatMap(contextElement => Array.from(contextElement.querySelectorAll<HTMLElement>(':not(a) > sup')));

    for (const sup of sups) {
        const supNumber = parseInt(sup.textContent!.trim(), 10);

        /* istanbul ignore if */
        if (Number.isNaN(supNumber)) {
            // this sup does not seem to be a footnote so we ignore it
            return;
        }

        const anchor = document.createElement('a');

        // move all attributes from sup to anchor

        for (const attribute of sup.attributes) {
            anchor.setAttribute(attribute.name, attribute.value);
            sup.removeAttribute(attribute.name);
        }

        /* istanbul ignore else */
        if (anchor.classList.length === 0) {
            // if the sup had no class, add the default footnote-link class
            anchor.classList.add(FOOTNOTE_LINK_CLASSNAME);
        }

        anchor.setAttribute('href', `#${Footnotes.getFootnoteId(supNumber)}`);

        // wrap sup in anchor
        sup.parentElement!.insertBefore(anchor, sup);
        anchor.appendChild(sup);
    }
};

/**
 * Observes the DOM for added nodes to automatically connect new footnotes.
 * @param contextElement The element to observe
 * @param callback The callback function which is called when nodes were added to the DOM.
 */
const observeDom = (contextElement: HTMLElement, callback: (targetElements: HTMLElement[]) => void) => {
    const observerConfig = { attributes: false, childList: true, subtree: true };

    const handleDomMutation = (records: MutationRecord[]) => {
        const hasAddedNodes = records.some(record => record.type === 'childList' && record.addedNodes.length);

        /* istanbul ignore if */
        if (!hasAddedNodes) {
            // if no nodes were added, there can't be new footnotes
            return;
        }

        // get target elements from records, avoiding duplicates
        const targetElements = [...new Set(records.map(record => record.target))] as HTMLElement[];

        callback(targetElements);
    };

    const observer = new MutationObserver(handleDomMutation);

    // observe the whole content area for new injected sups
    observer.observe(contextElement, observerConfig);
};

/**
 * Adds the click listener to the document and handles the clicks by checking if a valid footnote was clicked.
 * @param callback The callback function to be called when a valid footnote was clicked.
 */
const addClickListener = (callback: (targetFootnoteNumber: number) => void) => {
    document.addEventListener('click', e => {
        const target = e.target as HTMLAnchorElement;
        const closestFootnoteLink = target.closest(`a[href^="#${FOOTNOTE_ID_PREFIX}"]`);

        if (!closestFootnoteLink) {
            // click was not on a footnote-link
            return;
        }

        const targetFootnoteNumber = +closestFootnoteLink.getAttribute('href')!.trim().slice(1).replace(FOOTNOTE_ID_PREFIX, '');

        /* istanbul ignore if */
        if (Number.isNaN(targetFootnoteNumber)) {
            return;
        }

        e.preventDefault();

        callback(targetFootnoteNumber);
    });
};

/**
 * Checks the location hash (fragment) if it contains a footnote reference.
 * If yes and the footnote is found, it will scroll to the referenced footnote.
 * @param callback The callback function which is called with the footnote number
 * if the URL fragment contains a valid value.
 */
const scrollToFootnoteFromLocationHash = (callback: (targetFootnoteNumber: number) => void) => {
    const trimmedHash = window.location.hash.trim();

    if (!trimmedHash) {
        // no hash given
        return;
    }

    const pattern = new RegExp(`${FOOTNOTE_ID_PREFIX}(\\d+)`);
    const matches = trimmedHash.match(pattern);

    if (!matches?.length) {
        // hash is given but contains no footnote id
        return;
    }

    const footnoteNumber = parseInt(matches[1], 10);
    callback(footnoteNumber);
};
