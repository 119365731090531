import { META_NAV_ITEM_CONTACT_BUTTON_VISIBLE_CLASSNAME } from 'Container/ContactSection/constants';
import { isKeyboardTriggeredClickEvent } from '@vfde-sails/utils';
import { closestUntil } from 'Helper/domHelper';
import { DOMEvent } from '../../../interface';
import { ContactActionDispatchers } from 'Container/ContactSection/actions';

/**
 * Mount contact button
 */
const mountContactButton = (className: string, actions: ContactActionDispatchers): HTMLElement | undefined => {
    const containerElement = document.getElementsByClassName(className)[0] as HTMLElement;

    if (!containerElement) {
        return;
    }

    const contactClickHandler = (event: DOMEvent<MouseEvent, HTMLElement>): void => {
        event.preventDefault();
        const button = closestUntil(event.target, 'button', event.currentTarget);

        if (!button) {
            // ignore clicks inside the <li>
            // but outside the <button>
            return;
        }

        actions.toggleFlyout(isKeyboardTriggeredClickEvent(event));
    };

    containerElement.addEventListener('click', contactClickHandler);

    return containerElement;
};

/**
 * Toggle visibility of contact button
 */
export const toggleContactButton = (containerElement: HTMLElement, force?: boolean) => {
    containerElement.classList.toggle(META_NAV_ITEM_CONTACT_BUTTON_VISIBLE_CLASSNAME, force);
};

export default mountContactButton;
