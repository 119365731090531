import {
    call,
    CallEffect,
    fork,
    put,
    PutEffect,
    select,
    SelectEffect,
    takeLatest,
    throttle,
} from 'redux-saga/effects';
import {
    requestJson,
    RequestResponse,
} from '@vfde-sails/utils';
import {
    GET_SEARCH,
    NO_RESULT_ID,
    RESET_SEARCH,
    SEARCH_API_URL,
    SET_OVERLAY_OPEN,
} from './constants';
import { searchActionsCreators } from './actions';
import { groupSearchResult } from './helpers/groupSearchResult';
import { selectQuery } from './selector';
import {
    trackRevealSearchOverlay,
    saveSearchTrackingProperties,
    clearSearchTrackingProperties,
} from './helpers/tracking';
import { ISearchAPIResultItem } from 'Container/GlobalSearch/interface';

const {
    getSearchError,
    getSearchSuccess,
    setOverlayOpen,
} = searchActionsCreators;

/**
 * Triggered on each input event of the search input field.
 * Retrieves search results
 */
export function* getSearchSaga (): Generator<
    CallEffect<void>
    | SelectEffect
    | CallEffect<RequestResponse<unknown>>
    | PutEffect<ReturnType<typeof searchActionsCreators.getSearchSuccess>>
    | PutEffect<ReturnType<typeof searchActionsCreators.getSearchError>>,
    void,
    string | RequestResponse<ISearchAPIResultItem[]>> {
    const query = (yield select(selectQuery())) as string;
    const apiUrl = SEARCH_API_URL + query;

    try {
        const response = (yield call(requestJson, apiUrl)) as RequestResponse<ISearchAPIResultItem[]>;
        const searchResult = groupSearchResult(query, response.body!);

        yield put(getSearchSuccess(searchResult));

        // Don't track empty result
        if (searchResult.resultCount && searchResult.sections[0].id !== NO_RESULT_ID) {
            yield call(saveSearchTrackingProperties, {
                searchType: 'autosuggest',
                searchTerms: query,
                searchNumberOfResults: searchResult.resultCount,
            });
        }
    }
    catch (err) {
        yield put(getSearchError());
        yield call(clearSearchTrackingProperties);
    }
}

/**
 * Handle set overlay open
 */
export function* setOverlayOpenSaga (action: ReturnType<typeof setOverlayOpen>) {
    if (action.open) {
        yield fork(trackRevealSearchOverlay);
    }
}

/**
 * Reset search saga
 */
export function* resetSearchSaga () {
    yield call(clearSearchTrackingProperties);
}

/**
 * globalSearchSaga
 *
 */
export default function* globalSearchSaga () {
    yield throttle(100, GET_SEARCH, getSearchSaga);
    yield takeLatest(SET_OVERLAY_OPEN, setOverlayOpenSaga);
    yield takeLatest(RESET_SEARCH, resetSearchSaga);
}
