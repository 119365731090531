import {
    APP,
    GLOBAL_CLASSNAME_PREFIX,
} from 'Constant';

const replaceLevelInClassName = (className: string, level: number): string => className.replace('{level}', level.toString());

export const NAMESPACE = `${APP}/GLOBAL_NAVIGATION` as const;

// #region Actions

export const TRIGGER_SKIP_TO_LINK = `${NAMESPACE}/TRIGGER_SKIP_TO_LINK` as const;
export const SET_MDD_STATE = `${NAMESPACE}/SET_MDD_STATE` as const;

// #endregion

// #region Breadcrumb

export const BREADCRUMB_CLASSNAME = `${GLOBAL_CLASSNAME_PREFIX}-breadcrumb` as const;
export const BREADCRUMB_HIDDEN_CLASSNAME = `${BREADCRUMB_CLASSNAME}--hidden` as const;
export const BREADCRUMB_WRAPPER_CLASSNAME = `${BREADCRUMB_CLASSNAME}__wrapper` as const;
export const BREADCRUMB_LIST_CLASSNAME = `${BREADCRUMB_CLASSNAME}__list` as const;
export const BREADCRUMB_LIST_ITEM_CLASSNAME = `${BREADCRUMB_CLASSNAME}__list-item` as const;
export const BREADCRUMB_PREV_CLASSNAME = `${BREADCRUMB_CLASSNAME}__prev` as const;
export const BREADCRUMB_NEXT_CLASSNAME = `${BREADCRUMB_CLASSNAME}__next` as const;
export const BREADCRUMB_REVEAL_ICON_CLASSNAME = `${BREADCRUMB_CLASSNAME}__reveal-icon` as const;
export const BREADCRUMB_LIST_ITEM_LINK_CLASSNAME = `${BREADCRUMB_CLASSNAME}__list-item-link` as const;

// #endregion

// #region Global Navigation

export const HEADER_CLASSNAME = `${GLOBAL_CLASSNAME_PREFIX}-header` as const;
export const NAV_CLASSNAME = `${GLOBAL_CLASSNAME_PREFIX}-nav` as const;
export const NAV_BURGER_CLASSNAME = `${NAV_CLASSNAME}__burger` as const;
export const NAV_BURGER_ACTIVE_CLASSNAME = `${NAV_BURGER_CLASSNAME}--active` as const;
export const NAV_LIST_CLASSNAME = `${NAV_CLASSNAME}__list` as const;
export const NAV_MAIN_CONTAINER = `${NAV_CLASSNAME}__main-nav-container` as const;
export const NAV_ITEM_CLASSNAME = `${NAV_CLASSNAME}__item` as const;
export const NAV_ITEM_HOVER_CLASSNAME = `${NAV_ITEM_CLASSNAME}--hover` as const;
export const NAV_LINK_CLASSNAME = `${NAV_CLASSNAME}__link` as const;
export const NAV_TOGGLE_BUTTON_CLASSNAME = `${NAV_CLASSNAME}__toggle-button` as const;
export const NAV_SKIP_TO_CONTENT_CLASSNAME = `${NAV_CLASSNAME}__skip-to-content` as const;
export const NAV_LOGO_CLASSNAME = `${NAV_CLASSNAME}__logo` as const;
export const NAV_LOGO_ID = `${NAV_CLASSNAME}-logo` as const;
export const NAV_ICON_NAV_CLASSNAME = `${NAV_CLASSNAME}__icon-nav` as const;
export const NAV_ICON_NAV_ITEM_CLASSNAME = `${NAV_ICON_NAV_CLASSNAME}-item` as const;
export const NAV_ICON_NAV_LINK_CLASSNAME = `${NAV_CLASSNAME}__icon-nav-link` as const;
export const NAV_ROOT_LEVEL_ID = `${NAV_CLASSNAME}-list-1` as const;
export const NAV_META_NAV_CLASSNAME = `${NAV_CLASSNAME}__meta-nav` as const;
export const NAV_META_NAV_LIST_CLASSNAME = `${NAV_META_NAV_CLASSNAME}-list` as const;
export const NAV_META_NAV_LIST_LEFT_CLASSNAME = `${NAV_META_NAV_LIST_CLASSNAME}--left` as const;
export const NAV_META_NAV_LIST_RIGHT_CLASSNAME = `${NAV_META_NAV_LIST_CLASSNAME}--right` as const;
export const NAV_META_NAV_ITEM_CLASSNAME = `${NAV_META_NAV_CLASSNAME}-item` as const;
export const NAV_META_NAV_LINK_CLASSNAME = `${NAV_META_NAV_CLASSNAME}-link` as const;

const NAV_LIST_LEVEL_X_CLASSNAME = `${NAV_LIST_CLASSNAME}--level-{level}` as const;
export const NAV_LIST_LEVEL_1_CLASSNAME = replaceLevelInClassName(NAV_LIST_LEVEL_X_CLASSNAME, 1);
export const NAV_LIST_LEVEL_2_CLASSNAME = replaceLevelInClassName(NAV_LIST_LEVEL_X_CLASSNAME, 2);
export const NAV_LIST_LEVEL_3_CLASSNAME = replaceLevelInClassName(NAV_LIST_LEVEL_X_CLASSNAME, 3);

const NAV_ITEM_LEVEL_X_CLASSNAME = `${NAV_ITEM_CLASSNAME}--level-{level}` as const;
export const NAV_ITEM_LEVEL_1_CLASSNAME = replaceLevelInClassName(NAV_ITEM_LEVEL_X_CLASSNAME, 1);
export const NAV_ITEM_LEVEL_2_CLASSNAME = replaceLevelInClassName(NAV_ITEM_LEVEL_X_CLASSNAME, 2);
export const NAV_ITEM_LEVEL_3_CLASSNAME = replaceLevelInClassName(NAV_ITEM_LEVEL_X_CLASSNAME, 3);
export const NAV_ITEM_SELECTED_CLASSNAME = `${NAV_ITEM_CLASSNAME}--selected`;

const NAV_LINK_LEVEL_X_CLASSNAME = `${NAV_LINK_CLASSNAME}--level-{level}` as const;
export const NAV_LINK_LEVEL_1_CLASSNAME = replaceLevelInClassName(NAV_LINK_LEVEL_X_CLASSNAME, 1);
export const NAV_LINK_LEVEL_2_CLASSNAME = replaceLevelInClassName(NAV_LINK_LEVEL_X_CLASSNAME, 2);

export const NAV_ANIMATION_OUT = `${NAV_CLASSNAME}__list--animation-out` as const;
export const NAV_ANIMATION_IN = `${NAV_CLASSNAME}__list--animation-in` as const;

// #endregion

// #region Sublevel Navigation

export const SUBNAV_CLASSNAME = `${GLOBAL_CLASSNAME_PREFIX}-subnav` as const;
export const SUBNAV_LINK_CLASSNAME = `${SUBNAV_CLASSNAME}__link` as const;
export const SUBNAV_ITEM_CLASSNAME = `${SUBNAV_CLASSNAME}__item` as const;
export const SUBNAV_LIST_CONTAINER_CLASSNAME = `${SUBNAV_CLASSNAME}__list-container` as const;

const SUBNAV_LIST_LEVEL_X_CLASSNAME = `${SUBNAV_CLASSNAME}__list--level-{level}` as const;
export const SUBNAV_LIST_LEVEL_1_CLASSNAME = replaceLevelInClassName(SUBNAV_LIST_LEVEL_X_CLASSNAME, 1);
export const SUBNAV_LIST_LEVEL_2_CLASSNAME = replaceLevelInClassName(SUBNAV_LIST_LEVEL_X_CLASSNAME, 2);

const SUBNAV_ITEM_LEVEL_X_CLASSNAME = `${SUBNAV_ITEM_CLASSNAME}--level-{level}` as const;
export const SUBNAV_ITEM_LEVEL_1_CLASSNAME = replaceLevelInClassName(SUBNAV_ITEM_LEVEL_X_CLASSNAME, 1);
export const SUBNAV_ITEM_LEVEL_2_CLASSNAME = replaceLevelInClassName(SUBNAV_ITEM_LEVEL_X_CLASSNAME, 2);

const SUBNAV_LINK_LEVEL_X_CLASSNAME = `${SUBNAV_LINK_CLASSNAME}--level-{level}` as const;
export const SUBNAV_LINK_LEVEL_1_CLASSNAME = replaceLevelInClassName(SUBNAV_LINK_LEVEL_X_CLASSNAME, 1);
export const SUBNAV_LINK_LEVEL_2_CLASSNAME = replaceLevelInClassName(SUBNAV_LINK_LEVEL_X_CLASSNAME, 2);

export const SUBNAV_TOGGLE_BUTTON_CLASSNAME = `${SUBNAV_CLASSNAME}__toggle-button` as const;

// #endregion

// #region Footer

export const FOOTER_CLASSNAME = `${GLOBAL_CLASSNAME_PREFIX}-footer` as const;
export const FOOTER_TOGGLE_BUTTON_CLASSNAME = `${FOOTER_CLASSNAME}__toggle-button` as const;
export const FOOTER_TOGGLE_BUTTON_ACTIVE_CLASSNAME = `${FOOTER_TOGGLE_BUTTON_CLASSNAME}--active` as const;
export const FOOTER_ITEM_LEVEL_1_CLASSNAME = `${FOOTER_CLASSNAME}__item--level-1` as const;
export const FOOTER_LIST_LEVEL_2_CLASSNAME = `${FOOTER_CLASSNAME}__list--level-2` as const;

// #endregion
