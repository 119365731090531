import {
    connect,
    injectReducer,
    injectSaga,
    RootState,
} from '@vfde-sails/core';
import { createStructuredSelector } from 'reselect';
import './style.scss';
import {
    NAMESPACE,
    OVERLAY_WRAPPER_ID,
} from './constants';
import {
    SearchActionDispatchers,
    searchActionDispatchers,
} from './actions';
import {
    selectHasError,
    selectIsLoading,
    selectIsOverlayOpen,
    selectQuery,
    selectResults,
} from './selector';
import globalSearchReducer from './reducer';
import globalSearchSaga from './saga';
import mountSearchOverlay, { toggleOverlay } from 'Container/GlobalSearch/components/GlobalSearch';
import {
    renderSearchResults,
    resetSearchResults,
} from 'Container/GlobalSearch/components/SearchResultList';
import {
    IInitialState,
    IStateProps,
} from './interface';

function globalSearch (state: IStateProps, actions: SearchActionDispatchers) {
    injectReducer(NAMESPACE, globalSearchReducer);
    injectSaga(NAMESPACE, globalSearchSaga);

    const elements = mountSearchOverlay(OVERLAY_WRAPPER_ID, actions);

    return {
        /**
         * This function is called on first load and everytime the store changes.
         */
        getDerivedStateFromProps (newState: IStateProps, oldState: IStateProps) {
            if (newState.results && newState.results !== oldState.results && elements && elements.overlay) {
                renderSearchResults(elements, newState);
            }

            if (newState.isOverlayOpen !== oldState.isOverlayOpen) {
                toggleOverlay(elements!, newState.isOverlayOpen);
            }

            if (!newState.query && newState.query !== oldState.query) {
                resetSearchResults(elements!);
            }

            if (newState.hasError !== oldState.hasError) {
                elements?.errorNotification?.toggleContainer(!newState.hasError);
            }
        },
    };
}

/**
 * mapStateToProps = We are mapping the given states to props mapDispatch
 */
const mapStateToProps = createStructuredSelector<RootState<IInitialState>, IStateProps>({
    isLoading: selectIsLoading(),
    hasError: selectHasError(),
    query: selectQuery(),
    results: selectResults(),
    isOverlayOpen: selectIsOverlayOpen(),
});

const mountGlobalSearchContainer = connect(mapStateToProps, searchActionDispatchers)(globalSearch);

export default mountGlobalSearchContainer;
