import { NAMESPACE } from './constants';
import { initialState } from './reducer';
import {
    createSelector,
    Selector,
} from 'reselect';
import { RootState } from '@vfde-sails/core';
import { IInitialState } from './interface';

const selectState = (state: RootState<IInitialState>): IInitialState => state?.[NAMESPACE] || initialState;

const selectIsMddOpen = (): Selector<RootState<IInitialState>, IInitialState['isMddOpen']> =>
    createSelector(
        selectState,
        state => state.isMddOpen,
    );

export {
    selectIsMddOpen,
};
