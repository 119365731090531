import {
    FOOTER_CLASSNAME,
    FOOTER_ITEM_LEVEL_1_CLASSNAME,
} from 'Container/GlobalNavigation/constants';
import initNavigation from 'Container/GlobalNavigation/helpers/navigationHelper';

/**
 * Init footer
 */
export const mountFooter = (): CallableFunction[] => {
    const footerElement = document.getElementsByClassName(FOOTER_CLASSNAME)[0] as HTMLElement;
    const resizeHandlers: CallableFunction[] = [];

    if (!footerElement) {
        return resizeHandlers;
    }

    // Initialize footer navigation functionality
    const listItems = Array.from(footerElement.getElementsByClassName(FOOTER_ITEM_LEVEL_1_CLASSNAME) as HTMLCollectionOf<HTMLLIElement>);
    resizeHandlers.push(initNavigation(listItems));

    return resizeHandlers;
};
