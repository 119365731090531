import {
    connect,
    injectReducer,
    injectSaga,
    RootState,
} from '@vfde-sails/core';
import { createStructuredSelector } from 'reselect';
import {
    META_NAV_ITEM_CONTACT_BUTTON_CLASSNAME,
    META_NAV_ITEM_CONTACT_NUMBER_CLASSNAME,
    NAMESPACE,
} from './constants';
import constactSectionReducer from './reducer';
import {
    ContactActionDispatchers,
    contactActionDispatchers,
} from './actions';
import {
    selectContactNumber,
    selectIsFlyoutOpen,
} from './selector';
import './style.scss';
import mountContactNumber, {
    toggleContactNumber,
    updateContactNumber,
} from 'Container/ContactSection/components/ContactNumber';
import mountContactButton, { toggleContactButton } from 'Container/ContactSection/components/ContactButton';
import {
    IInitialState,
    IStateProps,
} from './interface';
import contactSectionSaga from 'Container/ContactSection/saga';
import beacon from '@vfde-sails/beacon';

const contactSection = (state: IStateProps, actions: ContactActionDispatchers) => {
    injectReducer(NAMESPACE, constactSectionReducer);
    injectSaga(NAMESPACE, contactSectionSaga);

    beacon.subscribe('sails:flyout', msg => {
        switch (msg.type) {
            case 'TOGGLE_FLYOUT_SUCCESS':
                actions.toggleFlyoutSuccess(msg.payload);
                break;
            case 'SET_CONTACT_INFO':
                actions.setDefaultState(msg.payload.simplicityContactInfo);
                break;
        }
    });

    const contactNumber = mountContactNumber(META_NAV_ITEM_CONTACT_NUMBER_CLASSNAME, actions);
    const contactButton = mountContactButton(META_NAV_ITEM_CONTACT_BUTTON_CLASSNAME, actions);

    return {
        /**
         * This function is called on first load and everytime the store changes.
         */
        getDerivedStateFromProps (newState: IStateProps, oldState: IStateProps) {
            if (newState.contactNumber !== oldState.contactNumber) {
                if (contactNumber) {
                    updateContactNumber(contactNumber, newState);
                    toggleContactNumber(contactNumber, !!newState.contactNumber);
                }

                contactButton && toggleContactButton(contactButton, !!newState.contactNumber);
            }
        },
    };
};

/**
 * mapStateToProps = We are mapping the given states to props mapDispatch
 */
const mapStateToProps = createStructuredSelector<RootState<IInitialState>, IStateProps>({
    contactNumber: selectContactNumber(),
    isFlyoutOpen: selectIsFlyoutOpen(),
});

const mountContactSectionContainer = connect(mapStateToProps, contactActionDispatchers)(contactSection);

export default mountContactSectionContainer;
