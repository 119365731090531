import {
    connect,
    injectReducer,
    injectSaga,
    RootState,
} from '@vfde-sails/core';
import {
    IInitialState,
    IStateProps,
} from './interface';
import { createStructuredSelector } from 'reselect';
import './style.scss';
import {
    NAMESPACE,
    NAV_ITEM_BASKET_CLASSNAME,
} from './constants';
import { selectBasketCount } from './selector';
import basketReducer from './reducer';
import loginSectionSaga from './saga';
import mountBasketLink, {
    BasketLinkElements,
    updateBasketCount,
} from './components/BasketLink';
import {
    BasketActionDispatchers,
    basketActionsDispatchers,
} from './actions';

function basket (state: IStateProps, actions: BasketActionDispatchers) {
    injectReducer(NAMESPACE, basketReducer);
    injectSaga(NAMESPACE, loginSectionSaga);

    const basketLinkElements = mountBasketLink(NAV_ITEM_BASKET_CLASSNAME) || ({} as BasketLinkElements);

    actions.getBasketCount();

    window.addEventListener('clearBasket', () => {
        // this event is dispatched on the basket page when you click the delete button (sails-checkout-app)
        actions.clearBasket();
    });

    return {
        /**
         * This function is called on first load and everytime the store changes.
         */
        getDerivedStateFromProps (newState: IStateProps, oldState: IStateProps) {
            if (oldState.basketCount !== newState.basketCount) {
                updateBasketCount(basketLinkElements, newState.basketCount);
            }
        },
    };
}

/**
 * mapStateToProps = We are mapping the given states to props mapDispatch
 */

const mapStateToProps = createStructuredSelector<RootState<IInitialState>, IStateProps>({
    basketCount: selectBasketCount(),
});

const mountBasketContainer = connect(mapStateToProps, basketActionsDispatchers)(basket);

export default mountBasketContainer;
