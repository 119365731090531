import {
    StrictEffect,
    call,
    put,
    takeLatest,
} from 'redux-saga/effects';
import {
    NAV_LOGO_ID,
    NAV_ROOT_LEVEL_ID,
    TRIGGER_SKIP_TO_LINK,
} from 'Container/GlobalNavigation/constants';
import { SkipToLinkType } from 'Container/GlobalNavigation/interface';
import { toggleMdd } from 'Container/GlobalNavigation/components/Mdd';
import {
    focusFirstElement,
    isNoMddMode,
} from 'Helper/domHelper';
import { navigationActionsCreators } from 'Container/GlobalNavigation/action';
import { isDesktopViewport } from '@vfde-brix/core';

const {
    setMddState,
    triggerSkipToLink,
} = navigationActionsCreators;

/**
 * Handles skip links
 */
export function* handleSkipToLinkSaga (action: ReturnType<typeof triggerSkipToLink>): Generator<StrictEffect> {
    yield call([window.location, 'assign'], action.href);

    switch (action.linkType) {
        case SkipToLinkType.Navigation: {
            const isInNoMddMode = (yield call(isNoMddMode)) as boolean;

            if (isInNoMddMode) {
                // if noMdd mode is active, focus the logo link (in all viewports)
                yield call(focusFirstElement, `#${NAV_LOGO_ID}`, null);
            }
            else if (isDesktopViewport()) {
                // if noMdd is not active and viewport is desktop, focus the nav root
                yield call(focusFirstElement, `#${NAV_ROOT_LEVEL_ID}`);
            }
            else {
                // if noMdd is not active and viewport is mobile, open the navi and and focus the first link
                yield put(setMddState(true));
                yield call(toggleMdd, true, true);
            }

            break;
        }
    }
}

/**
 * Main saga
 */
export default function* globalNavigationSaga (): Generator<StrictEffect> {
    yield takeLatest(TRIGGER_SKIP_TO_LINK, handleSkipToLinkSaga);
}
