import {
    BASKET_INDICATOR_CLASSNAME,
    BASKET_INDICATOR_COUNT_CLASSNAME,
} from '../constants';
import { CLASSNAME_HIDDEN } from '@vfde-brix/core';
import replaceCMSPlaceholder from 'Helper/replaceCMSPlaceholder';

/**
 * Basket Link Elements
 */
export interface BasketLinkElements {
    /**
     * The basketCountIndicator element
     */
    basketCountIndicator: HTMLElement;
    /**
     * The basketCount element
     */
    basketCount: HTMLElement;
}

/**
 * Reads the basket link and relevant child elements
 * from the DOM and returns an wrapper object
 * @param className The classname of the basketLink
 */
const mountBasketLink = (className: string): BasketLinkElements | null => {
    const basketLink = document.getElementsByClassName(className)[0] as HTMLAnchorElement;

    if (!basketLink) {
        return null;
    }

    const basketCountIndicator = basketLink.getElementsByClassName(BASKET_INDICATOR_CLASSNAME)[0] as HTMLElement;
    const basketCount = basketCountIndicator.getElementsByClassName(BASKET_INDICATOR_COUNT_CLASSNAME)[0] as HTMLElement;

    return {
        basketCountIndicator,
        basketCount,
    };
};

/**
 * Updates the basket count
 * @param basketLinkElements Basket link elements
 * @param basketCount Basket count
 */
export const updateBasketCount = (basketLinkElements: BasketLinkElements, basketCount: number) => {
    const { basketCountIndicator: basketCountIndicatorElement, basketCount: basketCountElement } = basketLinkElements;

    if (!basketCountIndicatorElement || !basketCountElement) {
        return;
    }

    const basketIsEmpty = !basketCount;

    basketCountElement.textContent = basketCount.toString();
    basketCountIndicatorElement.classList.toggle(CLASSNAME_HIDDEN, basketIsEmpty);

    const textResources = window.globalPageOptions?.basket?.textResources;

    if (!textResources) {
        // no textResources available
        return;
    }

    const ariaLabelAttribute = 'aria-label';

    if (basketIsEmpty) {
        if (basketCountIndicatorElement.hasAttribute(ariaLabelAttribute)) {
            // remove aria-label if basket was emptied
            basketCountIndicatorElement.removeAttribute(ariaLabelAttribute);
        }

        // don't set aria-label if basket is empty
        return;
    }

    const ariaLabel = replaceCMSPlaceholder(textResources.label, {
        productCount: basketCount.toString(),
        products: basketCount === 1 ? textResources.product : textResources.products,
    });

    basketCountIndicatorElement.setAttribute(ariaLabelAttribute, ariaLabel);
};

export default mountBasketLink;
