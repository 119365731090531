import { META_NAV_ITEM_CONTACT_NUMBER_VISIBLE_CLASSNAME } from 'Container/ContactSection/constants';
import { IStateProps } from '../interface';
import { isKeyboardTriggeredClickEvent } from '@vfde-sails/utils';
import { ContactActionDispatchers } from 'Container/ContactSection/actions';

/**
 * Render and mount contact number
 */
const mountContactNumber = (className: string, actions: ContactActionDispatchers): HTMLElement | undefined => {
    const containerElement = document.getElementsByClassName(className)[0] as HTMLElement;

    if (!containerElement) {
        return;
    }

    // click handler for mobile contact item and
    const contactClickHandler = (event: MouseEvent) => {
        event.preventDefault();
        actions.toggleFlyout(isKeyboardTriggeredClickEvent(event));
    };

    containerElement.addEventListener('click', contactClickHandler);

    return containerElement;
};

/**
 * Update contact number
 */
export const updateContactNumber = (containerElement: HTMLElement, state: Pick<IStateProps, 'contactNumber'>) => {
    const renderContactNumber = require('./templates/number.pug');

    containerElement.innerHTML = renderContactNumber({
        numberToDisplay: state.contactNumber!,
    });
};

/**
 * Toggle contact number visibility
 */
export const toggleContactNumber = (containerElement: HTMLElement, force?: boolean) => {
    containerElement.classList.toggle(META_NAV_ITEM_CONTACT_NUMBER_VISIBLE_CLASSNAME, force);
};

export default mountContactNumber;
