import {
    createSearchResultListComponent,
    ISearchResultListItem,
    ISearchResultListSection,
    SearchResultList,
} from '@vfde-brix/search-result-list';
import { NO_PATTERN_BUSINESS_LOGIC } from '@vfde-brix/core';
import {
    ISearchElements,
    IStateProps,
} from '../interface';

const mountSearchResultList = (className: string): SearchResultList => {
    const containerElement = document.getElementsByClassName(className)[0];

    const component = createSearchResultListComponent(containerElement, NO_PATTERN_BUSINESS_LOGIC);
    component.toggleContainer(true);

    return component;
};

/**
 * Is there only one warning item in the new state?
 */
const isWarningItemOnlyOrError = (resultsFromApi: ISearchResultListSection<ISearchResultListItem>[]): boolean => {
    const errorImageUrl = window.globalPageOptions?.search?.errorImageUrl || '';

    return resultsFromApi.length === 0 || (resultsFromApi[0].items.length === 1 && errorImageUrl === resultsFromApi[0].items[0].image);
};

/**
 * Render search result
 */
export const renderSearchResults = ({ searchResultList, showResultsBottomButton }: ISearchElements, { results }: IStateProps): void => {
    // in case of error or empty result hide the bottom button
    showResultsBottomButton.toggleContainer(isWarningItemOnlyOrError(results));

    searchResultList.update({
        sections: results,
    });

    // Show search results now
    searchResultList.toggleContainer(false);
};

/**
 * Reset the search
 */
export const resetSearchResults = ({ searchResultList, inputField, showResultsBottomButton }: ISearchElements): void => {
    showResultsBottomButton.toggleContainer(true);

    searchResultList.update({
        sections: [],
    });
    searchResultList.toggleContainer(true);

    inputField.resetInput();
};

export default mountSearchResultList;
