import {
    call,
    CallEffect,
    put,
    PutEffect,
    takeLatest,
} from 'redux-saga/effects';
import {
    LOGIN_COOKIE,
    GET_LOGIN_INFO,
    POST_LOGOUT,
    BSHOP_LOGOUT_URL,
} from './constants';
import { myVodafoneActionsCreators } from './actions';
import { ILoginInfo } from './interface';
import {
    deleteCookie,
    getCookie,
} from '@vfde-sails/utils';
const {
    getLoginInfoFailed,
    getLoginInfoSuccess,
} = myVodafoneActionsCreators;

/**
 * Reads login info from cookie
 */
export function* getLoginInfoSaga (): Generator<
    CallEffect<ReturnType<typeof getCookie>>
    | PutEffect<ReturnType<typeof myVodafoneActionsCreators.getLoginInfoSuccess>>
    | PutEffect<ReturnType<typeof myVodafoneActionsCreators.getLoginInfoFailed>>,
    void,
    ReturnType<typeof getCookie>> {
    const loginCookieValue = yield call(getCookie, LOGIN_COOKIE);

    if (!loginCookieValue) {
        return;
    }

    try {
        const lastLoginTimestamp = JSON.parse(decodeURIComponent(loginCookieValue)).lastLoginDate;
        const loginInfoFromCookie: ILoginInfo = { lastLoginTimestamp };
        yield put(getLoginInfoSuccess(loginInfoFromCookie));
    }
    catch (err) {
        yield put(getLoginInfoFailed(true));
    }
}

/**
 * Logs the user out from the bshop sesion and removes the login cookie
 */
export function* postLogoutSaga (action: ReturnType<typeof myVodafoneActionsCreators.postLogout>): Generator<
    CallEffect<Response>
| CallEffect<ReturnType<typeof deleteCookie>>
| CallEffect<[Window, 'assign']>,
    void,
    ReturnType<typeof myVodafoneActionsCreators.postLogout>
> {
    try {
        yield call(fetch, BSHOP_LOGOUT_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
        });

        yield call(deleteCookie, LOGIN_COOKIE);
    }
    catch (err) {
        yield call([console, 'warn'], 'Failed to logout from Bshop', err);
    }
    finally {
        yield call([window.location, 'assign'], action.redirectUrl);
    }
}

/**
 * loginSectionSaga
 */
export default function* loginSectionSaga () {
    yield takeLatest(GET_LOGIN_INFO, getLoginInfoSaga);
    yield takeLatest(POST_LOGOUT, postLogoutSaga);
}
